import React, { useState, useEffect, useRef } from 'react';

import './speedTyping.scss';

export default (): JSX.Element => {
    const [text, setText] = useState("");
    const [timeRemaining, setTimeRemaining] = useState(20);
    const [isTimeRunning, setIsTimeRunning] =useState(false);
    const [wordCount, setWordCount] = useState(0);
    const textareaRef = useRef(null);


    const handleChange = (e:any) => {
        const {value} = e.target
        setText(value)
    }

    const calcWordCount = (text: string) => {
        const wordsArr = text.trim().split(" ")
        return wordsArr.filter(word => word !== "").length
    }

    const startClock = () => {
        setIsTimeRunning(true)
        setTimeRemaining(20)
        setText("")
        setWordCount(0)
        textareaRef.current.disabled = false
        textareaRef.current.focus() 
    }

    const stopClock = () => {
        setIsTimeRunning(false)
        setWordCount(calcWordCount(text))
    }

    useEffect(() => {
        if(isTimeRunning && timeRemaining > 0) {
            setTimeout(() => {
                setTimeRemaining(time => time - 1)
            }, 1000)
        } else if(timeRemaining === 0) {
            stopClock()
        }
    }, [timeRemaining, isTimeRunning])

    return (
        <div className="speedTyping">
            <div>
                <h1>How fast do you type?</h1>
                <textarea ref={textareaRef} onChange={handleChange} value={text} disabled={!isTimeRunning} />
                <h4>Time remaining: {timeRemaining}</h4>
                <button onClick={startClock} disabled={isTimeRunning}>Start</button>
                <h1>Word count: {wordCount}</h1>
            </div>
        </div>
    );
};
