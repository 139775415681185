import React from 'react';
import Layout from '../components/layout/Layout';
import SpeedTyping from '../components/speedTyping/SpeedTyping';

import '../styles/styles.scss';

export default (): JSX.Element => {
    return (
        <Layout>
            <SpeedTyping />
        </Layout>
    );
};
